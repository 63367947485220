import {Axios} from "@/utils/axios";
import {tokenStore} from "@/utils/token-store";
import router from "@/router";

export class AuthServiceClass {

	public isLoggedIn: boolean = false;
	public appReady: boolean = false;
	public url: string = "https://booking-api.webcode.be/api"


	async init() {
		try {
			let response = await Axios.get(this.url + "/init");
			if (response.status == 200) {
				this.isLoggedIn = true;
				this.appReady = true;
				//let r = router.currentRoute.path
				//await router.push(r);
			}
		} catch (e) {
			this.appReady = true;
			await router.push('/login');
		}
	}

	async login(email: string, password: string) {

		let response = await Axios.post(this.url + "/user-auth", {email: email, password: password});
		if (response.status == 201) {
			tokenStore.token = response.data.data.data.token;
			this.isLoggedIn = true;
		}
		await router.push("/");
	}

	async logout() {
		tokenStore.token = null;
		this.isLoggedIn = false;
		await router.push('/login');
	}


}

export const AuthService = new AuthServiceClass();