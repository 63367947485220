<template>
  <v-navigation-drawer
      v-model="drawer"
      app clipped expand-on-hover permanent
      @input="update($event)"
  >
    <!--  <v-list>
      <v-list-item class="px-2">
         <v-list-item-avatar>
           <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
         </v-list-item-avatar>
       </v-list-item>

       <v-list-item link>
         <v-list-item-content>
           <v-list-item-title class="text-h6">
             Sandra Adams
           </v-list-item-title>
           <v-list-item-subtitle>sandra_a88@gmail.com</v-list-item-subtitle>
         </v-list-item-content>
       </v-list-item>
     </v-list>-->

    <v-divider></v-divider>

    <v-list
        dense
        nav
    >
      <v-list-item link to="/bookings">
        <v-list-item-icon>
          <v-icon>mdi-calendar-text-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Bookings</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/accomodations">
        <v-list-item-icon>
          <v-icon>mdi-bed</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Accomodaties</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/seasons">
        <v-list-item-icon>
          <v-icon>mdi-weather-sunset</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Seizoenen</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/discounts">
        <v-list-item-icon>
          <v-icon>mdi-wallet-giftcard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Kortingscodes</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'Drawer',
  data() {
    return {
      show: this.value
    }
  },
  props: {
    drawer: {
      required: true,
    }
  },
  methods:{
    update($event){ this.$emit('update',$event); }
  },
  watch: {
    drawer() {
      //this.show = this.drawer
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.v-list-item__icon{ margin-right: 18px!important; }
</style>
