import axios, { AxiosInstance } from "axios";
import {tokenStore} from "@/utils/token-store";

export function createAxiosClient():AxiosInstance {

	let config = {
		headers: { Authorization: `Bearer ${tokenStore.token}` }
	};

	let client = axios.create(config);
	client.interceptors.request.use(
		async config => {

			config.headers = {
				'Authorization': `Bearer ${tokenStore.token}`,
			}
			return config;
		},
		error => {
			Promise.reject(error)
		});

	return client;
}

export const Axios = createAxiosClient();