function save(key: string, value: string | null) {
	if (!value) {
		return localStorage.removeItem(key);
	}
	localStorage.setItem(key, value);
}

export const tokenStore = {

	get token(): string | null {
		return localStorage.getItem("token");
	},
	set token(value: string | null) {
		save("token", value);
	},

}